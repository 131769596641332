import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { APP_ROUTES } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleMapsModule } from '@angular/google-maps';

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13|14)_/.test(navigator.userAgent));

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(APP_ROUTES),
    importProvidersFrom([
      BrowserAnimationsModule.withConfig({ disableAnimations }),
      HttpClientModule,
      MatDialogModule,
      GoogleMapsModule,
    ]),
  ],
};
