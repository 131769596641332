import { Routes } from '@angular/router';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'roadside-assistance',
  },
  {
    path: 'roadside-assistance',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/auth-steps/auth-steps/auth-steps.component').then(
        c => c.AuthStepsComponent
      ),
    title: 'Form',
  },
  {
    path: 'geo',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/geo-page/geo-page/geo-page.component').then(
        c => c.GeoPageComponent
      ),
    title: 'GEO',
  },
  {
    path: 'tracker',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/tracker/tracker/tracker.component').then(
        m => m.TrackerComponent
      ),
    title: 'CIS/Tracker',
  },
  {
    path: '**',
    redirectTo: 'roadside-assistance',
  },
];
