<footer class="footer">
  <div class="container">
    <div class="footer-box">
      <div class="footer-info">
        <a
          class="footer-info__link"
          href="https://www.europcar.com/en-us"
          target="_blank">
          ©Europcar {{ year }}
        </a>
        All rights reserved.
      </div>
    </div>
  </div>
</footer>
