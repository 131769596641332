import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthStepsComponent } from './pages/auth-steps/auth-steps/auth-steps.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderViewComponent } from './core/header-view/header-view.component';
import { RouterOutlet } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MapFormControlComponent } from './components/map-form-control/map-form-control.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AuthStepsComponent,
    HeaderViewComponent,
    FooterComponent,
    RouterOutlet,
    MapFormControlComponent,
    ReactiveFormsModule,
  ],
  providers: [],
})
export class AppComponent {
  title = 'Roadside';
}
